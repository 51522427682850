.loader-container {
  position: absolute;
  height: 100vh;
  width: 100%;
  z-index: 10;
}

.loader-container .overlay {
  height: 100%;
  background: rgba(0, 0, 0, 0.834);
  opacity: 0.4;
}

.loader-container .spinner .path {
  stroke: var(--custom-timeline-color);
}