.tracking-container {
  height: 100%;
  position: relative;
}

.tracking-details {
  background-color: var(--main-bg-color);
  border-radius: var(--small-border-radius);
  top: 60%;
  position: absolute;
  width: 100%;
  z-index: 20;
}

.tracking-infos {
  display: flex;
}

.tab-content-center {
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  width: fit-content;
}

@media screen and (min-width: 576px) {
  .tracking-details {
    background-color: unset;
    left: 4rem;
    top: 7rem;
    bottom: 8rem;
    width: 26%;
  }
}

