.tracking-form-container {
  top: 20%;
  position: absolute;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  background-color: var(--main-bg-color);
  border-radius: var(--small-border-radius);
  box-shadow: var(--top-box-shadow);
  overflow: hidden;
}

.tracking-form-container .header {
  background: var(--primary-gradient);
  border-bottom: solid 1px var(--primary-color);
  padding: 1.2rem;
}

.tracking-form-container img {
  display: block;
  margin: auto;
  object-fit: contain;
  max-height: var(--header-height);
}

.tracking-form {
  padding: 1.5rem 2.5rem;
}

.tracking-form .label-input 			  { 
  position:relative; 
  margin-top: 2rem;
}

.label-input label {
  position: absolute;
  top:-20px;
  display: block;
  font-weight: 600;
}

.label-input label.error {
  color: var(--error-color);
}

.label-input input 				{
  padding: 0.5rem;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--light-font-color);
}

.tracking-form input.error,
.tracking-form input:focus {
  outline: none;
  border-bottom: none;
}

/* BOTTOM BARS ================================= */
.bar 	{
  position: relative;
  display: block;
}

.bar:before, .bar:after 	{
  content: '';
  height: 2px; 
  width: 0;
  bottom: 1px; 
  position: absolute;
  background: var(--primary-color); 
  transition: 0.2s ease width; 
}

.bar:before {
  left: 50%;
}
.bar:after {
  right: 50%; 
}

input.error ~ .bar:before, input.error ~ .bar:after,
input:focus ~ .bar:before, input:focus ~ .bar:after {
  width: 50%;
}

  /* active state */
input:focus ~ .bar:before, input:focus ~ .bar:after {
  background: var(--primary-color); 
}

/* error state */
input.error ~ .bar:before, input.error ~ .bar:after {
  background: var(--error-color); 
}

/* HIGHLIGHTER ================================== */
.highlight {
  position: absolute;
  height: 60%; 
  width: 80%; 
  top: 25%; 
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* active state */
input:focus ~ .highlight {
  animation: inputHighlighter 0.3s ease;
}

@keyframes inputHighlighter {
	from {
    background: var(--light-font-color);
  }
  to {
    width:0; background:transparent;
  }
}

.tracking-form .input-error {
  height: 1.5rem;
  color: var(--error-color)
}

.shiny-btn {
  display: block;
  position: relative;
  background: var(--primary-gradient);
  color: white;
  cursor: pointer;
  font-weight: 600;
  border-radius: var(--small-border-radius);
  border: none;
  padding: 0.6rem 5rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5rem;
}

.shiny-btn:hover {
  background: var(--primary-gradient-hover);
}
.shiny-btn::before,
.shiny-btn::after {
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  transform: translateX(-100px) skewX(-15deg);
}

.shiny-btn::before {
  background: rgba(255, 255, 255, 0.5);
  width: 60px;
  filter: blur(30px);
}

.shiny-btn::after {
  background: rgba(255, 255, 255, 0.2);
  width: 30px;
  filter: blur(5px);
}

.shiny-btn:hover::before,
.shiny-btn:hover::after {
  transform: translateX(300px) skewX(-15deg);
  transition: 1s;
}

.shiny-btn:active {
  top: 2px;
}

.tracking-form .spinner .path {
  stroke: white;
}

@media screen and (min-width: 576px) {
  .tracking-form-container {
    margin: 0;
    width: 400px;
    top: calc(50% - 100px);
    left: calc(50% - 200px);
  }
}
