.download-container {
  border: var(--default-border);
  border-radius: var(--default-border-radius);
  margin-top: 1rem;
  padding: 1rem;
}

.download-container a{
  text-align: center;
}

.download-container img{
  height: 3.5rem;
  margin: auto;
  margin-bottom: 0.3rem;
}