.header-container {
  background-color: var(--custom-bg-color);
  border-radius: 0 0 var(--default-border-radius) var(--default-border-radius);
  color: var(--custom-font-color);
  height: var(--header-height);
  padding: 0 0.5rem;
  position: absolute;
  width: 100%;
  z-index: 20;
}

.header-center .logo {
  object-fit: contain;
  max-height: calc(var(--header-height) - 1rem);
}

.header-end {
  text-align: right;
}

.dd-wrapper {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  position: relative;
  height: 100%;
}

.dd-language-options {
  position: absolute;
  z-index: 20;
  list-style: none;
  padding: 0.5rem;
  background-color: var(--custom-bg-color);
  border: var(--default-border);
  border-radius: var(--default-border-radius);
  box-shadow: var(--top-box-shadow);
  top: var(--header-height);
  right: 0;
  width: fit-content;
}

.dd-language-options button {
  color: var(--custom-font-color);
}

.locale-button {
  width: fit-content;
  height: var(--header-height);
  padding: 0.5rem;
}

.custom-fill {
  width: 2rem;
  height: 2rem;
  margin: 0;
  background-color: var(--custom-font-color);
  mask: url(../../../../assets/icons/locale.svg) no-repeat center / contain;
  -webkit-mask: url(../../../../assets/icons/locale.svg) no-repeat center / contain;
}