.mapboxgl-ctrl .mapboxgl-ctrl-logo,
.mapboxgl-ctrl.mapboxgl-ctrl-attrib.mapboxgl-compact {
  display: none;
}

.mapboxgl-ctrl-group:not(:empty) {
  background-color: var(--main-bg-color);
  border-radius: var(--default-border-radius);
  box-shadow: var(--bottom-box-shadow);
}

.mapboxgl-ctrl-group button.mapboxgl-ctrl-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 3rem;
  width: 3rem;
}

.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-group {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.mapboxgl-ctrl-bottom-right {
  top: 53%;
}

@media screen and (min-width: 576px) {
  .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-group {
    margin-right: 4rem;
  }

  .mapboxgl-ctrl-bottom-right {
    top: unset;
    bottom: 0;
  }

  
}