
a {
  color: var(--default-a-color);
}

.gs-container-illustration {
  align-items: center;
  background: var(--main-bg-color);
  display: flex;
  font-size: 2rem;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.gs-box-illustration {
  background-color: var(--light-bg-color);
  border-radius: var(--large-border-radius);
}

.gs-content-illustration {
  background-color: var(--main-bg-color);
  border-radius: var(--small-border-radius);
  padding: 1.5rem 3rem;
}

.gs-illustration {
  margin: auto;
  padding: 2rem 0;
}

.bg-white {
  background-color: #fff;
}

.bg-main-color {
  background-color: var(--main-bg-color);
}

.border-top {
  border-top: var(--default-border);
}

.fc-light {
  color: var(--light-font-color)
}

.fc-secondary {
  color: var(--secondary-font-color);
}

.fs-small {
  font-size: var(--small-font-size);
}

.fs-big {
  font-size: var(--big-font-size);
}

.fw-bold {
  font-weight: 700;
}

.fw-semibold {
  font-weight: 600;
}

.h-100 {
  height: 100%;
}

.unstyled-button {
  padding: 0.5rem;
  background-color: unset;
  border: none;
}

.pre-line {
  white-space: pre;
}

.td-none {
  text-decoration: none;
}

@media screen and (min-width: 576px) {
  .gs-box-illustration {
    padding: 4rem 6rem;
  }
}