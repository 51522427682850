.contact-container {
  padding: 1.5rem;
}

.contact-container >*:where(:not(:last-child)) {
  margin-bottom: 1.4rem;
}

.contact-container a {
  display: flex;
  align-items: center;
}

.contact-container img {
  margin-right: 1rem;
}