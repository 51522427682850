.ref-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  padding-left: 1rem;
}

.ref-card{
  box-shadow: var(--card-box-shadow);
  border-radius: var(--default-border-radius);
  width: fit-content;
  padding: 1rem;
  margin: 1rem;
}

.ref-card span {
  white-space: normal;
}

@media screen and (min-width: 576px) {
  .ref-container {
    padding-top: 1rem;
  }
}