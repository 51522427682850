.marker {
  background-size: cover;
  border-radius: 50%;
  height: var(--map-marker-size);
  width: var(--map-marker-size);
}

.marker.mapboxgl-marker {
  top: -14px;
}

.map-to-marker {
  background-image: url('../assets/icons/map-to.svg');
}

.map-truck-marker {
  background-image: url('../assets/icons/map-truck.svg');
}

.map-from-marker {
  background-image: url('../assets/icons/map-from.svg');
}