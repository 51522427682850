
.refreshTime {
  background-color: hsl(0, 0%, 30%);
  border-radius: var(--small-border-radius) var(--small-border-radius) 0 0;
  text-align: center;
  color: #fff;
  padding: 0.3rem;
}

.border-radius {
  border-radius: var(--small-border-radius) var(--small-border-radius) 0 0;
}  

.courier-container {
  align-items: center;
  background-color: var(--main-bg-color);
  box-shadow: var(--top-box-shadow);
  display: flex;
  padding: 1.5rem;
}

@media screen and (min-width: 576px) {
  .courier-container {
    border-radius: 0 0 var(--small-border-radius) var(--small-border-radius);
  }

  .border-radius {
    border-radius: var(--small-border-radius);
  }  
}