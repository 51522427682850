.datetime-container-sm {
  display: flex;
  height: var(--header-height);
  border-radius: 0 0 var(--default-border-radius) var(--default-border-radius);
  position: absolute;
  top: var(--header-height);
  right: 0;
  left: 0;
  width: fit-content;
  margin-left: auto; 
  margin-right: auto;
  z-index: 20;
  box-shadow: var(--bottom-box-shadow);
  background-color: var(--main-bg-color);
}

.datetime-container-lg {
  border-radius: var(--default-border-radius);
  margin: 1.5rem;
  padding: 1rem;
  padding-right: 3rem;
  width: fit-content;
}

.datetime-container-sm img {
  padding-right: 1rem;
}

.datetime-container-sm.estimated {
  padding: 0.5rem 1rem;
}

.datetime-container-sm.scheduled {
  padding: 1rem;
}

.datetime-container-lg.estimated {
  background-color: white;
  box-shadow: var(--card-box-shadow);
}

.datetime-container-lg.scheduled {
  width: calc(100% - 3rem);
  background-color: hsl(0, 0%, 30%);
  color: #fff;
}

.fc-estimated-time {
  color: var(--custom-timeline-color);
}

@media screen and (min-width: 576px) {
  .datetime-container-lg.scheduled  {
    width: unset;
  }
}