@keyframes blinking {
  0% {
    background-color: var(--custom-timeline-color);
  }
  50% {
    background-color: white;
  }
  100% {
    background-color: var(--custom-timeline-color);
  }
}

@keyframes blinking-warning {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.timeline-container {
  background-color: var(--main-bg-color);
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.timeline-container::after {
  background-color: var(--custom-timeline-color);
  content: '';
  position: absolute;
  top: 2.5rem;
  left: 2rem;
  width: 2px;
  height: calc(100% - 6.2rem);
}

.timeline-item {
  margin-left: 2rem;
  padding: 1rem;
  position: relative;
}

.timeline-item:where(:last-child) {
  margin-top: 1rem;
}

.timeline-item .circle {
  border: 2px solid var(--custom-timeline-color);
  border-radius: 50%;
  position: absolute;
  top: 1rem;
  left: calc(-2rem - 3px);
  width: 20px;
  height: 20px;
  z-index: 10;
}

.timeline-item .warning-circle {
  background-color: white;
  position: absolute;
  top: 1rem;
  left: calc(-2rem - 3px);
  fill: var(--custom-timeline-color);
  width: 20px;
  height: 20px;
  z-index: 10;
}

.timeline-item.done .circle {
  background-color: var(--custom-timeline-color);
}

.timeline-item.todo .circle {
  background-color: white;
}

.timeline-item.last-event .circle {
  animation: blinking 2s infinite;
}

.timeline-item.litigation-last-event .warning-circle {
  animation: blinking-warning 2s infinite;

}

@media screen and (min-width: 576px) {
  .timeline-container  {
  padding-bottom: 1.5rem;
  }
}
