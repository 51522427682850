.tabs-container {
  border-radius: var(--small-border-radius);
  margin-top: 1.5rem;
  height: var(--tab-panel-height);
  overflow: hidden;
}

.tabs-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  box-shadow: var(--top-box-shadow);
  height: var(--header-height);
}

.tab {
  background-color: #fff;
  text-align: center;
}

.tab button{
  background-color: unset;
  border: none;
  height: 100%;
}

.tab > div {
  background-color: var(--custom-timeline-color);
  width: 0;
  height: 4px;
  border-radius: 2px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -3px;
  transition: width .35s;
}

.tab > div.active {
  width: 80%;
}

.tab button.active{
  font-weight: 600;
}
