:root {
  --small-font-size: 0.8rem;
  --big-font-size: 1.4rem;

  --custom-bg-color: #fff;
  --custom-timeline-color: #ff671d;
  --custom-font-color: #ff671d;
  --light-font-color: #787878;
  --primary-color: #ff671d;
  --primary-gradient: linear-gradient(74.24deg,#ff4712,#ff671d 96.86%);
  --primary-gradient-hover: linear-gradient(74.24deg,#f74e1e,#f6793d 96.86%);
  --error-color: #D40000;
  --secondary-font-color: #333;
  --default-a-color: #0081BB;

  --main-bg-color: #FBFCFF;
  --light-bg-color: #F1F1F1;
  --border-color: #C9C9C9;

  --small-border-radius: 5px;
  --default-border-radius: 10px;
  --big-border-radius: 15px;
  --large-border-radius: 20px;

  --header-height: 4.6rem;
  --tab-panel-height: 80%;

  --top-box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
  --bottom-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  --card-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  --default-border: 1px solid var(--border-color);
  --clip-path: polygon(0 0, 100% 0, 100% 0, 100% 0%, 100% 100%, 30px 100%, 0% 100%);
  --map-marker-size: 32px;

  font-family: PlusJakarta;
  font-size: 13px;
}